import React from 'react';
import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { GatsbyImage } from 'gatsby-plugin-image';
import { WrapMax } from '$components/Wraps';
import FadeInSection from '$components/FadeInSection';
import getImageData from '$utils/getImageData';
import { BlockLogotypesProps } from './types';
import Heading2 from '$components/Heading2';
import dynamicPadding from '$utils/dynamicPadding';

const StyledImage = styled(GatsbyImage)`
  width: 100%;
`;

const Outer = styled.section`
  background-color: var(--color-champ4);
  ${dynamicPadding};
  position: relative;
`;

const Inner = styled.div`
  display: grid;
  text-align: center;
`;

const Logotypes = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;
  list-style: none;
  padding: 0;

  @media screen and (min-width: 1160px) {
    padding: 0 3.25rem;
  }

  @media screen and (min-width: 1280px) {
    padding: 0 7.4rem;
  }

  @media screen and (min-width: 1440px) {
    padding: 0;
  }
`;

const Logotype = styled.li`
  width: 6.75rem;
`;

const LogotypeWithLink = ({ children, link }) =>
  link ? (
    <Logotype>
      <AniLink to={link} bg="var(--color-citrus5)" cover direction="left">
        {children}
      </AniLink>
    </Logotype>
  ) : (
    <Logotype>{children}</Logotype>
  );

const BlockLogotypes: React.FC<BlockLogotypesProps> = ({ heading, logotypes }) => (
  <FadeInSection>
    <Outer>
      <WrapMax>
        <Inner>
          <Heading2 large>{heading}</Heading2>

          <Logotypes>
            {logotypes.map(({ Image: image, Link: link }) => (
              <LogotypeWithLink link={link}>
                <StyledImage image={getImageData(image[0].imageFile)} alt={image[0].alternativeText} />
              </LogotypeWithLink>
            ))}
          </Logotypes>
        </Inner>
      </WrapMax>
    </Outer>
  </FadeInSection>
);

export default BlockLogotypes;
